<template>
    <div class="home">
        <div class="top flex">
            <img class="logo" @click="goHome('')" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" @click="goSign">赛事报名</div>
            </div>
        </div>
        <div class="crumbs"><span style="color: #45B79C">赛道一</span> | <span @click="$router.push('/saidao2')">赛道二</span> | <span @click="$router.push('/saidao3')">赛道三</span> | <span @click="$router.push('/saidao4')">赛道四</span> | <span @click="$router.push('/saidao5')">赛道五</span></div>
        <div class="banner">
            <div class="title">赛道一</div>
            <div class="desc">常熟文创形象“虞小主”卡通形象设计</div>
        </div>
        <div class="content flex-vc" >
            <div class="left">
                <div class="text1">垆边人似月，皓腕凝霜雪</div>
                <div class="text2">抿唇一笑，温柔了岁月。扶柳分花，点缀了时光。 <br>她芳龄20，来自常熟；她有着江南的灵秀之气，又是英姿飒爽的“小主”；她的美如同虞山之美，越看越养眼；她知书达理，但精灵古怪；她亦动亦静，温顺中略带刁蛮，乖顺中时不时会闯点小祸，她就是虞小主。</div>
            </div>
            <div class="right">
                <div class="text1">参赛者根据以下要求进行设计</div>
                <div class="text3">
①方案中须有“虞小主”卡通形象三视图，包含：正面、侧面、背面，并设计三个或三个以上能表现该形象特色的动作或表情（萌化表情及肢体动作等）、变装；<br> ②方案中须配上必要的文字说明，包括：形象姓名、性别等基本信息，作品创作思路或设计说明简述等； <br> ③方案中须有“虞小主”动漫形象的场景应用，比如古装、马拉松选手、城市白领等等。<br>  ④可有但不限于“虞小主”形象的文创周边设计和在公共设施&产品延展 <br> ⑤请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式
                </div>
            </div>
        </div>
        <!-- footer -->
        <div id="sign" class="footer">
        <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
        <div class="text-wrap flex-vcbetween">
            <div>
            <div><span>指导单位</span>中共常熟市委宣传部</div>
            <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
            <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
            </div>
            <div class="flex-vc">
            <div><span>官方热线:</span></div>
            <div>
                <div>邮箱: 962991363@qq.com</div>
                <div>微信: 13817518262</div>
            </div>
            </div>
        </div>
        <div class="text-bottom flex-between">
            <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
            <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        goSign() {
            this.$router.push('/sign')
        },
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
    }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  .top {
    background: #FFFFFF;
    height: 134px;
    border-bottom: 1px solid #EBEBEB;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
    .crumbs {
        width: 1200px;
        line-height: 76px;
        font-size: 20px;
        color: #231815;
        text-align: left;
        margin: 0 auto;
        span {
          cursor: pointer;
        }
    }
    .banner {
        width: 1200px;
        height: 126px;
        background: url('~@/assets/saidao/bg@2x.png');
        background-size: 100% 100%;
        margin: 0 auto;
        color: #FFFFFF;
        padding-top: 20px;
        .title {
            font-size: 44px;
            font-weight: bold;
        }
        .desc {
            margin-top: 10px;
            font-size: 24px;
        }

    }
    .content {
        height: 554px;
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        line-height: 36px;
        .left {
            width: 600px;
        }
        .right {
            width: 540px;
            padding-left: 60px;
        }
        .text1 {
            font-size: 20px;
            color: #231815;
            font-weight: bold;
            margin-top: 32px;
        }
        .text2 {
            margin-top: 14px;
            font-size: 18px;
            color: #231815;
            width: 546px;
        }
        .text3 {
            width: 506px;
            height: 406px;
            border: 1px solid #E5E5E5;
            overflow-y: scroll;
            margin-top: 14px;
            padding: 17px;
            font-size: 18px;
            color: #231815;
        }
    }
      .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }
  }
}
</style>


